import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  BsFillGridFill,
  BsPersonFill,
  BsBook,
  BsBriefcaseFill,
  BsLaptop,
  BsAwardFill,
} from "react-icons/bs";
import { SiGooglemeet } from "react-icons/si";
import { MdPayment } from "react-icons/md";
import {
  FaBriefcase,
  FaCalendarCheck,
  FaCertificate,
  FaChalkboardTeacher,
  FaEnvelopeOpenText,
  FaFileAlt,
  FaFileContract,
  FaGoogle,
  FaGraduationCap,
  FaHandshake,
  FaProjectDiagram,
  FaTachometerAlt,
  FaUserCheck,
  FaUserGraduate,
  FaUserPlus,
  FaUserTie,
  FaVideo,
  FaList
} from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { FaTasks } from "react-icons/fa";

export const Sidebar = () => {
  const [sidebarMenu, setSidebarMenu] = useState<any[]>([]);

  useEffect(() => {
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    getSidebarMenuList(data?.role?.roleName);
  }, []);

  let getSidebarMenuList = (role: string) => {
    let list: any = [];
    if (role === "user") {
      list = [
        {
          name: "Dashboard",
          route: "/dashboard",
          icon: FaTachometerAlt, // Icon changed to FaTachometerAlt for dashboard
        },
        {
          name: "Internships",
          route: "/user/internships",
          icon: FaBriefcase, // Icon changed to FaBriefcase for internships
        },
        {
          name: "Certificates",
          route: "/user/certificates",
          icon: FaCertificate, // Icon changed to FaCertificate for certificates
        },
        {
          name: "LMS",
          route: "/user/cms",
          icon: FaChalkboardTeacher, // Icon changed to FaChalkboardTeacher for LMS
        },
        {
          name: "Career Guidance",
          route: "/user/training",
          icon: FaUserGraduate, // Icon changed to FaUserGraduate for career guidance
        },
        {
          name: "CGPA Building",
          route: "/user/projects",
          icon: FaProjectDiagram, // Icon changed to FaProjectDiagram for CGPA building
        },
        {
          name: "Admit Letter",
          route: "/user/letterhead",
          icon: FaFileContract, // Icon changed to FaFileContract for LOA
        },
        {
          name: "LOA",
          route: "/user/loa",
          icon: FaFileAlt, // Changed to FaFileAlt for LOA (document icon)
        },
      ];
    } else if (role === "admin") {
      list = [
        {
          name: "Eduexpose Interns",
          route: "/dashboard",
          icon: FaUserGraduate, // Changed to FaUserGraduate (graduate icon)
        },
        {
          name: "Prospect List",
          route: "/admin/prospectlist",
          icon: FaUserTie, // Changed to FaUserTie (business person icon)
        },
        {
          name: "Ambassador Data",
          route: "/admin/ambassadordata",
          icon: FaHandshake, // Changed to FaHandshake (handshake icon)
        },
        {
          name: "Registered Students",
          route: "/register/students",
          icon: FaUserCheck, // Changed to FaUserCheck (user check icon)
        },
        {
          name: "Subscribers",
          route: "/admin/queries",
          icon: FaEnvelopeOpenText, // Changed to FaEnvelopeOpenText (envelope icon)
        },
        // {
        //   name: "Amb",
        //   route: "/admin/certificatelist",
        //   icon: FaCertificate, // Changed to FaCertificate
        // },
        {
          name: "Admit Letter",
          route: "/admin/loa",
          icon: FaFileAlt, // Changed to FaFileAlt for LOA (document icon)
        },
        {
          name: "Role Access",
          route: "/admin/roleAccess",
          icon: FaTasks, // Changed to FaFileAlt for LOA (document icon)
        },
      ];
    } else if (role === "support") {
      list = [
        {
          name: "Manage Courses",
          route: "/dashboard",
          icon: FaChalkboardTeacher, // Changed to FaChalkboardTeacher
        },
        {
          name: "Certificates",
          route: "/support/certificatelist",
          icon: FaCertificate, // Changed to FaCertificate
        },
        {
          name: "LMS Videos",
          route: "/support/lmsvideolist",
          icon: FaVideo, // Changed to FaVideo
        },
        {
          name: "Project",
          route: "/support/projectlist",
          icon: FaProjectDiagram, // Changed to FaProjectDiagram
        },
        {
          name: "Student CGPA",
          route: "/support/cgpalist",
          icon: FaGraduationCap, // Changed to FaGraduationCap
        },
        {
          name: "Career Guidances",
          route: "/support/careerGuidances",
          icon: FaBriefcase, // Changed to FaBriefcase
        },
        {
          name: "Google Meet List",
          route: "/support/meetslist",
          icon: FaGoogle,
        },
        {
          name: "LOA",
          route: "/support/loa",
          icon: FaFileAlt,
        },
        {
          name: "Task List",
          route: "/support/tasklist",
          icon: FaTasks,
        },
        {
          name: "Added SignUpList",
          route: "/support/signUpList",
          icon: FaUserPlus,
        },
      ];
    } else if (role === "manager") {
      list = [
        {
          name: "Eduexpose Interns",
          route: "/dashboard",
          icon: FaUserGraduate, // Changed to FaUserGraduate (graduate icon)
        },
        {
          name: "Conversion list",
          route: "/manager/conversionList",
          icon: FaUserCheck, // Changed to FaUserCheck (user check icon)
        },
        {
          name: "Amb",
          route: "/admin/certificatelist",
          icon: FaCertificate, // Changed to FaCertificate
        },
      ];
    } else if (role === "bdi") {
      list = [
        {
          name: "Conversion List",
          route: "/dashboard",
          icon: FaList, // Changed to FaCertificate
        },
      ];
    }
    setSidebarMenu(list);
  };

  return (
    <div className="primary-bg text-white">
      <div className="sidebar_a">
        <ul className="nav-list px-0 pt-4">
          {sidebarMenu.map((data: any, index: number) => (
            <NavLink
              key={index}
              to={data.route}
              className="text-decoration-none text-white sidemenu-list-item"
            >
              <li className="py-2 pe-5 text-start ps-3 py-3 sidemenu-item px-4 border-bottom border-white">
                <data.icon className="me-2" />
                &nbsp; &nbsp;&nbsp;
                {data.name}
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};
