export const formConfig = {
  createUser: [
    {
      label: "Name",
      type: "text",
      name: "name",
      initialValue: "",
    },
    {
      label: "Phone",
      type: "text",
      name: "phone",
      initialValue: "",
    },
    {
      label: "Email",
      type: "text",
      name: "email",
      initialValue: "",
    },
    {
      label: "Password",
      type: "text",
      name: "password",
      initialValue: "",
    },
    {
      label: "Role",
      type: "select",
      name: "role",
      initialValue: "user",
      options: ["user", "admin", "editor"],
    },
  ],
  createMeet: [
    {
      label: "User Name",
      type: "text",
      name: "userName",
      initialValue: "",
    },
    {
      label: "Password",
      type: "text",
      name: "password",
      initialValue: "",
    },
    {
      label: "Link",
      type: "text",
      name: "link",
      initialValue: "",
    },
    {
      label: "Meet Date",
      type: "date",
      name: "meetDate",
      initialValue: "",
    },
  ],
  createReviews: [
    {
      label: "Name",
      type: "text",
      name: "name",
      initialValue: "",
    },
    {
      label: "text",
      type: "text",
      name: "text",
      initialValue: "",
    },
  ],
  registerNow: [
    {
      label: "Name",
      type: "text",
      name: "name",
      initialValue: "",
    },
    {
      label: "Phone",
      type: "text",
      name: "phone",
      initialValue: "",
    },
    {
      label: "Email",
      type: "text",
      name: "email",
      initialValue: "",
    },
  ]
};


export const courses = [
  {
    id: 1,
    name: 'Data Science',
    value: 'DataScience'
  },
  {
    id: 2,
    name: 'Artificial intelligence',
    value: 'ArtificialIntelligence '
  },
  {
    id: 3,
    name: 'Auto Cad',
    value: 'Auto Cad'
  },
  {
    id: 4,
    name: 'Machine learning ',
    value: 'MachineLearning'
  },
  {
    id: 5,
    name: 'IOT & Embedded Systems',
    value: 'IOTEMBEDDED SYSTEMS'
  },
  {
    id: 6,
    name: 'Startup and enterprenuership',
    value: 'Startupandenterprenuership'
  },
  {
    id: 7,
    name: 'VLSI',
    value: 'VLSI'
  },
  {
    id: 8,
    name: 'Robotics using python',
    value: 'ROBOTICSUSINGPYTHON '
  },
  {
    id: 9,
    name: 'Cyber security',
    value: 'CYBERSECURITY'
  },
  {
    id: 10,
    name: 'CLOUD COMPUTING',
    value: 'CLOUDCOMPUTING'
  },
  {
    id: 11,
    name: 'Web Development',
    value: 'Webdevelopment'
  },

]

export const modeOfCommunication = [
  {
    id: 1,
    name: 'Telugu',
    value: 'telugu'
  },
  {
    id: 2,
    name: 'English',
    value: 'english'
  },
  {
    id: 3,
    name: 'Hindi',
    value: 'hindi'
  },
]

export const roles = [
  {
    id: 1,
    name: 'User',
    value: 'user'
  },
  {
    id: 2,
    name: 'Support',
    value: 'support'
  },
  {
    id: 3,
    name: 'BDI',
    value: 'bdi'
  },
  {
    id: 4,
    name: 'Operations',
    value: 'operations'
  },
  {
    id: 5,
    name: 'Admin',
    value: 'admin'
  },
  {
    id:6,
    name:"Manager",
    value:'manager'
  }
]

export const Mroles = [
  {
    id: 3,
    name: 'BDI',
    value: 'bdi'
  }
]

export const years = [
  {
    id: 1,
    name: 1,
    value: 1
  },
  {
    id: 2,
    name: 2,
    value: 2
  },
  {
    id: 3,
    name: 3,
    value: 3
  },
  {
    id: 4,
    name: 4,
    value: 4
  },
]