import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/user-dashboard/sidebar";
import { Header } from "../../components/user-dashboard/header";
import { UserDashboard } from "../user-dashbard/user-dashboard-pages/dashboard";
import { ROLES } from "../../app/utility/app-codes";
import UsersList from "../admin-dashboard/users/usersList";
import ManageCourseList from "../support-dashboard/ManageCourses/manageCourseList";
import { useLocation } from "react-router-dom"; // Import useLocation to access URL params
import { AdminService } from "../../app/service/admin.service";
import ConversionList from "../Bdi/ConversionList";
import ManagerList from "../manager-dashboard/managerList/ManagerList";

export const Dashboard = () => {
  const userdata: any = sessionStorage.getItem("loginData");
  const userRole = JSON.parse(userdata);

  const location = useLocation();  // Get the location object that contains the URL
  const [email, setEmail] = useState('');

  // Extract the email from the query string using useLocation
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);  // Use location.search for query params
    const emailFromUrl = urlParams.get('email');
    if (emailFromUrl) {
      setEmail(emailFromUrl);  // Set the email to the state
    }
  }, [location]);  // Run the effect when location changes

  // API call to generate token using the email
  useEffect(() => {
    if (email) {
      getApi();
    }
  }, [email]);  // Trigger the API call when the email is available

  const getApi = () => {
    if (!email) {
      console.error("Email is missing");
      return;
    }

    let payload = { email: email };

    AdminService.generateToken(payload)
      .then((res) => {
        if (res.status === "Fail") {
         
        } else {
          sessionStorage.setItem("token", res?.data?.token);
          sessionStorage.setItem("loginData", JSON.stringify(res.data));
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
      });
  };

  return (
    <div className="container-fluid ">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg">
          {ROLES.Admin === userRole?.role?.roleName && <UsersList />}
          {ROLES.User === userRole?.role?.roleName && <UserDashboard />}
          {ROLES.Support === userRole?.role?.roleName && <ManageCourseList />}
          {ROLES.Manager === userRole?.role?.roleName && <ManagerList />}
          {ROLES.BDI === userRole?.role?.roleName && <ConversionList />}
        </div>
      </div>
    </div>
  );
};
