import { DataTableCol } from "../../../components/tables/types";

export const RoleAccessDataGridCols: DataTableCol[] = [
    {
        title: 'Role Name',
        control: 'roleName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Description',
        control: 'description',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Role Access',
        control: 'roleAccess',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];