import React, { useState } from "react";
import { toast } from "react-toastify";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";

const CreateRole = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    roleName: "",
    displayName: "",
    description: "",
  });

  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'courseName' ? value.split(',') : value, // Handle courseName as an array
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors:any = {};


    if (!formData.roleName) {
      newErrors.roleName = "Role Name is required";
    }

    if (!formData.displayName) {
      newErrors.displayName = "Display Name is required";
    }

    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = (e:any) => {
    e.preventDefault();

    if (validate()) {
      AdminService.createrole(formData).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/admin/roleAccess");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/admin/roleAccess");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Add New Admin Role</b>
          </h4>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Role Name *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${errors.roleName ? "is-invalid" : ""}`}
                    placeholder="Role Name"
                    name="roleName"
                    value={formData.roleName}
                    onChange={handleInput}
                  />
                  {errors.roleName && (
                    <div className="invalid-feedback">{errors.roleName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Display Name *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${errors.displayName ? "is-invalid" : ""}`}
                    placeholder="Display Name"
                    name="displayName"
                    value={formData.displayName}
                    onChange={handleInput}
                  />
                  {errors.displayName && (
                    <div className="invalid-feedback">{errors.displayName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Description *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${errors.description ? "is-invalid" : ""}`}
                    placeholder="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleInput}
                  />
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={handleRegister}
              >
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateRole;
