import { DataTableCol } from "../../components/tables/types";

export const ConversionsDataGridCols: DataTableCol[] = [
    {
        title: 'Date',
        control: 'createdAt',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Name',
        control: 'firstName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Phone Number',
        control: 'phone',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Course',
        control: 'courseName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Access key',
        control: 'cAccesskey',
        canShowColumn: true
    },
    {
        title: 'ADD',
        control: 'AccessButton',
        sortable: false,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: false,
        canShowColumn: false
    },
];