import React, { useState } from "react";
import { toast } from "react-toastify";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { Mroles } from "../../../config";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";

const CreateManager = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    role: "",
    dateOfJoining: "",
    phone: "",
    password: "",
    confirmPassword: "",
    profileUrl: "https://res.cloudinary.com/devatchannel/image/upload/v1602752402/avatar/avatar_cugq40.png",
    courseName: []
  });

  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'courseName' ? value.split(',') : value, // Handle courseName as an array
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors:any = {};

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
    }

    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
    }

    if (!formData.role) {
      newErrors.role = "Role is required";
    }

    if (!formData.dateOfJoining) {
      newErrors.dateOfJoining = "Date of Joining is required";
    }
    if (!formData.courseName) {
      newErrors.courseName = "Course Name is required";
    }

    if (!formData.phone) {
      newErrors.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = (e:any) => {
    e.preventDefault();

    if (validate()) {
      AdminService.addUser(formData).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/dashboard");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Add New Admin User</b>
          </h4>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">First Name *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${errors.firstName ? "is-invalid" : ""}`}
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInput}
                  />
                  {errors.firstName && (
                    <div className="invalid-feedback">{errors.firstName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Last Name *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${errors.lastName ? "is-invalid" : ""}`}
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInput}
                  />
                  {errors.lastName && (
                    <div className="invalid-feedback">{errors.lastName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Email *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${errors.email ? "is-invalid" : ""}`}
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInput}
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Phone *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${errors.phone ? "is-invalid" : ""}`}
                    placeholder="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInput}
                  />
                  {errors.phone && (
                    <div className="invalid-feedback">{errors.phone}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Date of Joining *</label>
                  <input
                    type="date"
                    className={`form-control mt-2 ${errors.dateOfJoining ? "is-invalid" : ""}`}
                    name="dateOfJoining"
                    value={formData.dateOfJoining}
                    onChange={handleInput}
                  />
                  {errors.dateOfJoining && (
                    <div className="invalid-feedback">{errors.dateOfJoining}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Select Role *</label>
                  <select
                    className={`form-control mt-2 ${errors.role ? "is-invalid" : ""}`}
                    name="role"
                    value={formData.role}
                    onChange={handleInput}
                  >
                    <option value="">Select Role</option>
                    {Mroles.map((role, index) => (
                      <option value={role.value} key={index}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                  {errors.role && (
                    <div className="invalid-feedback">{errors.role}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Photo *</label>
                  <input
                    type="file"
                    className="form-control mt-2"
                    name="photo"
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Courses (comma separated) *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${errors.courseName ? "is-invalid" : ""}`}
                    placeholder="Enter courses"
                    name="courseName"
                    value={formData.courseName.join(',')}
                    onChange={handleInput}
                  />
                  {errors.courseName && (
                    <div className="invalid-feedback">{errors.courseName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Password *</label>
                  <input
                    type="password"
                    className={`form-control mt-2 ${errors.password ? "is-invalid" : ""}`}
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInput}
                  />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Confirm Password *</label>
                  <input
                    type="password"
                    className={`form-control mt-2 ${errors.confirmPassword ? "is-invalid" : ""}`}
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInput}
                  />
                  {errors.confirmPassword && (
                    <div className="invalid-feedback">{errors.confirmPassword}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={handleRegister}
              >
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateManager;
