import API from "../utility/axios";
export class AdminService {
    static addUser(data: any): Promise<any> {
        return API.post(`/createUser`, data);
    }

    static createrole(data: any): Promise<any> {
        return API.post(`/createrole`, data);
    }

    

    static adminUpdateUser(data: any): Promise<any> {
        return API.post(`/adminUpdateUser`, data);
    }

    static updateConversionList(data: any): Promise<any> {
        return API.post(`/updateConversionList`, data);
    }

    

    static generateToken(data: any): Promise<any> {
        return API.post(`/generateToken`, data);
    }

    static getConversions(data: any): Promise<any> {
        return API.post(`/getConversions`, data);
    }

    static getManagerConversions(data: any): Promise<any> {
        return API.post(`/getManagerConversions`, data);
    }

    

    
    static getAllUserDetails(data: any): Promise<any> {
        return API.get(`/allUsersList`, { params: data });
    }


    static getConversionList(data: any): Promise<any> {
        return API.get(`/getConversionList`,{ params: data});
    }

    
    static activeUser(data: any): Promise<any> {
        return API.post(`/activeuser`, data);
    }

    static getManagerList(data: any): Promise<any> {
        return API.post(`/getManagerList`, data);
    }

    

    static createMeet(data: any): Promise<any> {
        return API.post(`/createmeet`, data);
    }
    static createPayment(data: any): Promise<any> {
        return API.post(`/createPayment`, data);
    }
    static updateMeet(data: any): Promise<any> {
        return API.post(`/updatemeet`, data);
    }

    static deleteUser(data: any): Promise<any> {
        return API.post(`/deleteUser`, data);
    }

    static deleteRegister(data: any): Promise<any> {
        return API.post(`/deleteRegister`, data);
    }

    static updateRegisterAmb(data: any): Promise<any> {
        return API.post(`/updateRegisterAmb`, data);
    }

    static deletePayment(data: any): Promise<any> {
        return API.post(`/deletePayment`, data);
    }
    static userAccess(data: any): Promise<any> {
        return API.post(`/userAccess`, data);
    }
    static getAllMeets(data: any): Promise<any> {
        return API.get(`/allmeets`);
    }
    static createCourse(data: any): Promise<any> {
        return API.post(`/createcourse`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static updateCourse(data: any): Promise<any> {
        return API.post(`/updatecourse`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static getAllRegisterList(data: any): Promise<any> {
        return API.get(`/allRegisterList`, { params: data });
    }

    static getAllrolesList(data: any): Promise<any> {
        return API.get(`/allroles`, { params: data });
    }

    

    static getAllPaymentList(data: any): Promise<any> {
        return API.get(`/getPayment`, { params: data });
    }

    static getAllQueriesList(data: any): Promise<any> {
        return API.get(`/allQueriesList`, { params: data });
    }

    static deleteQueries(data: any): Promise<any> {
        return API.post(`/deleteQueries`, data);
    }

    static deleteMeet(data: any): Promise<any> {
        return API.post(`/deleteMeet`, data);
    }

    static deleteCareerGuidances(data: any): Promise<any> {
        return API.post(`/deleteCareerGuidance`, data);
    }

    static deleteCgpa(data: any): Promise<any> {
        return API.post(`/deleteCgpa`, data);
    }

    

    static getCourseList(data: any): Promise<any> {
        return API.get(`/allcourses`, { params: data });
    }

    static deleteCourse(data: any): Promise<any> {
        return API.post(`/deleteCourse`, data);
    }

    static deleteLms(data: any): Promise<any> {
        return API.post(`/deleteLms`, data);
    }

    static courseInfo(data: any): Promise<any> {
        return API.post(`/courseInfo`, data);
    }

    static getallCertificatesList(data: any): Promise<any> {
        return API.get(`/allCertificatesList`, { params: data });
    }

    static getallProjectsList(data: any): Promise<any> {
        return API.get(`/allprojects`, { params: data });
    }

    static createCertificate(data: any): Promise<any> {
        return API.post(`/createCertificate`, data);
    }

    static createLoa(data: any): Promise<any> {
        return API.post(`/createLoa`, data);
    }

    static createAttendance(data: any): Promise<any> {
        return API.post(`/createAttendance`, data);
    }


    // static createCertificate(data: any): Promise<any> {
    //     console.log("data",data)
    //     return API.post(`/createCertificate`, data, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     });
    // }
    static createProject(data: any): Promise<any> {
        return API.post(`/createProject`, data);
    }

    static createTask(data: any): Promise<any> {
        return API.post(`/createTask`, data);
    }

    static getAllCgpaList(data: any): Promise<any> {
        return API.get(`/allCgpaLists`, { params: data });
    }
    static getallCareerGuidancesList(data: any): Promise<any> {
        return API.get(`/allCareerGuidances`, { params: data });
    }

    static createCgpaAccess(data: any): Promise<any> {
        return API.post(`/createCgpaAccess`, data);
    }
    static updateCareerGuidance(data: any): Promise<any> {
        return API.post(`/updateCareerGuidance`, data);
    }
    static createLms(data: any): Promise<any> {
        return API.post(`/createLms`, data);
    }
    static editLms(data: any): Promise<any> {
        return API.post(`/editLms`, data);
    }
    

    static getallLmslist(data: any): Promise<any> {
        return API.get(`/allLms`, { params: data });
    }
    static allLmsData(data: any): Promise<any> {
        return API.post(`/allLmsData`, data );
    }

    
    static createSignupList(data: any): Promise<any> {
        return API.post(`/createSignUpList`, data);
    }
  


    static allSignUpList(data: any): Promise<any> {
        return API.get(`/allSignUpList`, { params: data });
    }
    static allTasks(data: any): Promise<any> {
        return API.get(`/allTasks`, { params: data });
    }

    

    static deleteSignUpList(data: any): Promise<any> {
        return API.post(`/deleteSignupList`, data);
    }
    static deleteTaskList(data: any): Promise<any> {
        return API.post(`/deleteTaskList`, data);
    }

    
    static getAllSignUpList(data: any): Promise<any> {
        return API.get(`/allSignUpList`);
    }


}